const reviewDetails = [
    { id: 1, title:'Cody Hines', totalstar: 3, delay: "0.4s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
    { id: 2, title:'Chad Herrera', totalstar: 2, delay: "0.5s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
    { id: 3, title:'Andre Gonzalez', totalstar: 2, delay: "0.6s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
    { id: 4, title:'Jon Banks', totalstar: 3, delay: "0.7s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
    { id: 5, title:'Landon Houston', totalstar: 4, delay: "0.7s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
    { id: 6, title:'Nelle Wade', totalstar: 4, delay: "0.9s", description:'Accessories Here you can find the best computer accessory for your laptop, monitor, printer, scanner, speaker.'},
];

export {
    reviewDetails
};