import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { servicesDetails } from "../../data/services";

const ServicesDetails = (props) => {
    const { title, delay, icon, description } = props;
    return (
        <Col lg={4} md={6} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card.Body className="single-service">
                <h4><span className={"lnr lnr-" + icon}></span>{title}</h4>
                <Card.Text>{description}</Card.Text>
            </Card.Body>
        </Col>
    );
};
const Service = () => {
    return (
        <>
            {/* Start Service area */}
            <Card id="service" className="service-area section-gap">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='menu-content pb-60'>
                            <Card.Body className="title text-center">
                                <h1 className="mb-10">Some Features that Made us Unique</h1>
                                <Card.Text>
                                    Who are in extremely love with eco friendly system.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {servicesDetails.map(service => <ServicesDetails key={`service-${service.id}`} {...service} />)}
                    </Row>
                </Container>
            </Card>
            {/* End Service */}
        </>
    );
};

export default Service;