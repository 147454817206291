import React, { useEffect, useState } from 'react';
import { Container, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const HeaderNavbar = () => {

    const [name, setName] = useState('');

    const handleMouseOver = () => {
        var header = document.getElementById("nav");
        var PageLi = header.getElementsByClassName("PageLi");
        PageLi[0]?.classList?.add('sfHover');
    };

    const handleMouseOut = () => {
        var header = document.getElementById("nav");
        var PageLi = header.getElementsByClassName("PageLi");
        PageLi[0].classList.remove('sfHover');
    };

    useEffect(() => {
        var header = document?.getElementById("nav");
        var btns = header?.getElementsByClassName("liNav");
        for (var i = 0; i < btns.length; i++) {
            btns[i].addEventListener("click", function () {
                var current = document?.getElementsByClassName("current");
                current[0].className = current[0].className.replace(" current", "");
                this.className += " current";
            });
        }        
    }, [name]);

    return (
        <>
            <Container id="nav-menu-container" className='pl-5'>
                <Nav id="nav" className="nav-menu sf-arrows" as="ul">
                    <Nav.Item as="li" className='liNav current menu-active'>
                        <HashLink smooth to='/#home' onClick={() => setName('Home')}>Home</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className='liNav'>
                        <HashLink smooth to='/#about' onClick={() => setName('About')}>About</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className='liNav'>
                        <HashLink smooth to='/#service' onClick={() => setName('Service')}>Service</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className='liNav'>
                        <HashLink smooth to='/#unique' onClick={() => setName('Unique Feature')}>Unique Feature</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className='liNav'>
                        <HashLink smooth to='/#review' onClick={() => setName('Review')}>Review</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className='liNav'>
                        <HashLink smooth to='/#faq' onClick={() => setName('Faq')}>Faq</HashLink>
                    </Nav.Item>
                    <Nav.Item as="li" className={"liNav PageLi"} onMouseOver={handleMouseOver}>
                        <HashLink smooth to="/#home" onClick={() => setName('Pages')} className='sf-with-ul'>Pages</HashLink>
                        <Nav.Item as="ul" className='submenuPage' onMouseOut={handleMouseOut}>
                            <Nav.Item as="li">
                                <Link to="/generic">Generic</Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link to="/elements">Elements</Link>
                            </Nav.Item>
                        </Nav.Item>
                    </Nav.Item>
                </Nav>
            </Container>{/* #nav-menu-container */}
        </>
    );
};

export default HeaderNavbar;