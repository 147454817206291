import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import WOW from 'wowjs';
import Element from "../components/userLayout/Element/Element";
import Generic from "../components/userLayout/Generic";
import Home from "../components/userLayout/Home";
import Layout from "../components/userLayout/Layout";

function App() {

  useEffect(() => {
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      mobile: false,
      offset: 0,
      live: true,
      callback: function (box) {
        // the callback is fired every time an animation is started
        // the argument that is passed in is the DOM node being animated
      },
      scrollContainer: null
    }).init();
  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/generic' element={<Generic />} />
            <Route path='/elements' element={<Element />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
