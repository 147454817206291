import $ from 'jquery';
import React, { useEffect, useRef } from 'react';
import { Col, Container, Image, Navbar, Row } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import HeaderNavbar from './HeaderNavbar';


const Header = () => {

    const ref = useRef(null);

    useEffect(() => {

        if ($('#nav-menu-container').length) {
            var $mobile_nav = $('#nav-menu-container').clone().prop({
                id: 'mobile-nav'
            });
            $mobile_nav.find('> ul').attr({
                'class': '',
                'id': 'mnav'
            });
            $('body').append($mobile_nav);
            $('body').append('<div id="mobile-body-overly"></div>');
            $('#mobile-nav').find('.menu-has-children').prepend('<i class="lnr lnr-chevron-down"></i>');

            $(document).on('click', '.menu-has-children i', function (e) {
                $(this).next().toggleClass('menu-item-active');
                $(this).nextAll('ul').eq(0).slideToggle();
                $(this).toggleClass("lnr-chevron-up lnr-chevron-down");
            });


        } else if ($("#mobile-nav, #mobile-nav-toggle").length) {
            $("#mobile-nav, #mobile-nav-toggle").hide();
        }
    });

    useEffect(() => {
        const handleClick = event => {
            $(document).on('click', '#mobile-nav-toggle', function (e) {
                $('body').toggleClass('mobile-nav-active');
                $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                $('#mobile-body-overly').toggle();
            });

            $(document).click(function (e) {
                var container = $("#mobile-nav, #mobile-nav-toggle");
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    if ($('body').hasClass('mobile-nav-active')) {
                        $('body').removeClass('mobile-nav-active');
                        $('#mobile-nav-toggle i').toggleClass('lnr-cross lnr-menu');
                        $('#mobile-body-overly').fadeOut();
                    }
                }
            });
            var $li = $('#mobile-nav .liNav a').on('click', function () {
                $li.removeClass('mselected');
                $(this).addClass('mselected');
            });
            if(window.location.pathname === '/')
            {
                $('#mobile-nav .liNav:first-child a').addClass('mselected');
                
            }else if(window.location.pathname === '/elements')
            {
                $('#mobile-nav .liNav:last-child .submenuPage .nav-item:last-child a').addClass('mselected');
            }
            else if(window.location.pathname === '/generic')
            {
                $('#mobile-nav .liNav:last-child .submenuPage .nav-item:first-child a').addClass('mselected');
            }
        };

        const element = ref.current;

        element?.addEventListener('click', handleClick);

        return () => {
            element?.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <>
            <Navbar id="header">
                <Container>
                    <Row className="align-items-center justify-content-between d-flex">
                        <Col lg={5} id="logo">
                            <HashLink smooth to="/#home" as="div">
                                <Image src="watchWhiteLogo.png" className='digiLogo' />
                            </HashLink>
                        </Col>
                        <Col lg={7}><HeaderNavbar /></Col>
                    </Row>
                </Container>
            </Navbar>{/* #header */}

            <button type="button" ref={ref} id="mobile-nav-toggle"><i className="lnr lnr-menu"></i></button>

        </>
    );
};

export default Header;