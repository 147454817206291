import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Banner from './Banner';
import BlockQuotes from './BlockQuotes';
import Buttons from './Buttons';
import Definition from './Definition';
import FormElements from './FormElements';
import ImageGallery from './ImageGallery';
import LeftAligned from './LeftAligned';
import Lists from './Lists';
import RightAligned from './RightAligned';
import Table from './Table';
import TextSample from './TextSample';



const Element = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner/>
            <TextSample/>
            <Buttons/>
            {/* Start Align Area */}
            <div className="whole-wrap">
                <Container>                    
                    <LeftAligned/>
                    <RightAligned/>
                    <Definition/>
                    <BlockQuotes/>
                    <Table/>
                    <ImageGallery/>
                    <Lists/>
                    <FormElements/>
                </Container>
            </div >
            {/* End Align Area */}
        </>
    );
};

export default Element;