import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Definition = () => {

    return (
        <>
        <Card className="section-top-border">
                <h3 className="mb-30">Definition</h3>
                <Row>
                    <Col md={4}>
                        <div className="single-defination">
                            <h4 className="mb-20">Definition 01</h4>
                            <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks</Card.Text>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="single-defination">
                            <h4 className="mb-20">Definition 02</h4>
                            <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks</Card.Text>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="single-defination">
                            <h4 className="mb-20">Definition 03</h4>
                            <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks</Card.Text>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default Definition;