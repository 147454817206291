import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Images = [
    { src: "assets/img/elements/g1.jpg", col: 4 },
    { src: "assets/img/elements/g2.jpg", col: 4 },
    { src: "assets/img/elements/g3.jpg", col: 4 },
    { src: "assets/img/elements/g4.jpg", col: 6 },
    { src: "assets/img/elements/g5.jpg", col: 6 },
    { src: "assets/img/elements/g6.jpg", col: 4 },
    { src: "assets/img/elements/g7.jpg", col: 4 },
    { src: "assets/img/elements/g8.jpg", col: 4 }
];
const ImageGallery = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    
    return (
        <>
            <Card className="section-top-border">
                <h3>Image Gallery</h3>
                <Row>
                    {Images.map((data, index) => (
                        <Col md={data.col} key={index}>
                            <Image
                                className="single-gallery-image"
                                src={data.src}
                                alt=""
                                key={index}
                                onClick={() => {
                                    setIndex(index);
                                    setIsOpen(true);
                                }} />
                        </Col>
                    ))}
                    <SlideshowLightbox
                        images={Images}
                        showThumbnails={true}
                        open={isOpen}
                        lightboxIdentifier="lbox1"
                        startingSlideIndex={index}
                        onClose={() => { setIsOpen(false) }}>
                    </SlideshowLightbox>
                </Row>
            </Card>
        </>
    );
};

export default ImageGallery;