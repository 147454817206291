import React from 'react';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const buttonGroup1 = [
    { id: 1, title: 'Default', class1: 'default', class2: 'default-border', class3: 'default radius', class4: 'default-border radius', class5: 'default circle', class6: 'default-border circle', class7: 'default circle arrow', class8: 'default-border circle arrow' },
    { id: 2, title: 'Primary', class1: 'primary', class2: 'primary-border', class3: 'primary radius', class4: 'primary-border radius', class5: 'primary circle', class6: 'primary-border circle', class7: 'primary circle arrow', class8: 'primary-border circle arrow' },
    { id: 3, title: 'Success', class1: 'success', class2: 'success-border', class3: 'success radius', class4: 'success-border radius', class5: 'success circle', class6: 'success-border circle', class7: 'success circle arrow', class8: 'success-border circle arrow' },
    { id: 4, title: 'Info', class1: 'info', class2: 'info-border', class3: 'info radius', class4: 'info-border radius', class5: 'info circle', class6: 'info-border circle', class7: 'info circle arrow', class8: 'info-border circle arrow' },
    { id: 5, title: 'Warning', class1: 'warning', class2: 'warning-border', class3: 'warning radius', class4: 'warning-border radius', class5: 'warning circle', class6: 'warning-border circle', class7: 'warning circle arrow', class8: 'warning-border circle arrow' },
    { id: 6, title: 'Danger', class1: 'danger', class2: 'danger-border', class3: 'danger radius', class4: 'danger-border radius', class5: 'danger circle', class6: 'danger-border circle', class7: 'danger circle arrow', class8: 'danger-border circle arrow' },
    { id: 7, title: 'Link', class1: 'link', class2: 'link-border', class3: 'link radius', class4: 'link-border radius', class5: 'link circle', class6: 'link-border circle' }
];

const buttonGroup2 = [
    { id: 1, title: 'Extra Large', class1: 'primary e-large', class2: 'primary-border e-large' },
    { id: 2, title: 'Large', class1: 'success large', class2: 'success-border large' },
    { id: 3, title: 'Default', class1: 'primary', class2: 'primary-border' },
    { id: 4, title: 'Medium', class1: 'success medium', class2: 'success-border medium' },
    { id: 5, title: 'Small', class1: 'primary small', class2: 'primary-border small' }
];

const Buttons = () => {

    return (
        <>
            {/* Start Button */}
            <Card className="button-area">
                <Container className="border-top-generic">
                    <h3 className="text-heading">Sample Buttons</h3>
                    <div className="button-group-area">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class1} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-10">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class2} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-40">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class3} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable radius" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-10">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class4} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable radius" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-40">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class5} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable circle" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-10">
                        {buttonGroup1.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class6} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                        <Card.Link href="#" className="genric-btn disable circle" style={{ marginLeft: '0px' }}>Disable</Card.Link>
                    </div>
                    <div className="button-group-area mt-40">
                        {buttonGroup1.map((btn,index) => (
                            btn.class7 && <Card.Link href="#" key={index} className={"genric-btn " + btn.class7} style={{ marginLeft: '0px' }}>{btn.title}<span className="lnr lnr-arrow-right" /></Card.Link>
                        ))}
                    </div>
                    <div className="button-group-area mt-10">
                        {buttonGroup1.map((btn,index) => (
                            btn.class7 && <Card.Link href="#" key={index} className={"genric-btn " + btn.class8} style={{ marginLeft: '0px' }}>{btn.title}<span className="lnr lnr-arrow-right" /></Card.Link>
                        ))}
                    </div>
                    <div className="button-group-area mt-40">
                        {buttonGroup2.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class1} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                    </div>
                    <div className="button-group-area mt-10">
                        {buttonGroup2.map((btn,index) => (
                            <Card.Link href="#" key={index} className={"genric-btn " + btn.class2} style={{ marginLeft: '0px' }}>{btn.title}</Card.Link>
                        ))}
                    </div>
                </Container>
            </Card>
            {/* End Button */}


        </>
    );
};

export default Buttons;