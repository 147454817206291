import { SlideshowLightbox } from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const media = [
    {
        type: "yt",
        videoID :"IWVJq-4zW24",
        thumbnail: "https://img.youtube.com/vi/IWVJq-4zW24/hqdefault.jpg",
        alt: "Brand new app to blow your mind"
    }
]
const VideoAbout = () => {
    return (
        <>
            {/* About */}
            <Card id="about" className="video-sec-area section-gap">
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={6} className="video-left wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <h6>Brand new app to blow your mind</h6>
                            <h1>We’ve made a life <br />
                                that will change you</h1>
                            <Card.Text><span>We are here to listen from you deliver exellence</span></Card.Text>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore magna aliqua. Ut enim ad minim.
                            </Card.Text>
                            <Card.Link className="primary-btn" href="#">Get Started now</Card.Link>
                        </Col>
                        <Col lg={6} className="video-right d-flex justify-content-center align-items-center wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <div className="overlay overlay-bg"></div>
                            <div className="play-btn">
                                <SlideshowLightbox images={media} lightboxIdentifier="lbox1">
                                    <Image className="img-fluid" src={"assets/img/play-icon.png"}  data-lightboxjs="lbox1"/>
                                </SlideshowLightbox>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End About */}

        </>
    );
};

export default VideoAbout;