import React, { useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { featuresDetails } from "../../data/feature";

const FeatureDetails = (props) => {
    const { delay, image } = props;
    return (
        <Col lg={3} md={6} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card.Body className="single-unique-product">
                <Image className="img-fluid" src={"assets/img/" + image} />
                <div className="desc">
                    <h4>
                        Apple Watch White
                    </h4>
                    <h6>£399.00</h6>
                    <Card.Link className="text-uppercase primary-btn" href="#">Pre Order</Card.Link>
                </div>
            </Card.Body>
        </Col>
    );
};

const Generic = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            {/* Banner */}
            <Card className="generic-banner relative">
                <Container>
                    <Row className='height align-items-center justify-content-center'>
                        <Col lg={10}>
                            <Card.Body className="generic-banner-content">
                                <h2 className="text-white">The Generic Page</h2>
                                <Card.Text className="text-white">It won’t be a bigger problem to find one video game lover in your <br /> neighbor. Since the introduction of Virtual Game.</Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Banner */}

            {/* About Generic */}
            <div className="main-wrapper">
                {/* Features Area */}
                <Card id="unique" className="unique-feature-area section-gap">
                    <Container>
                        <Row className='d-flex justify-content-center'>
                            <Col lg={10} className='menu-content pb-60'>
                                <Card.Body className="title text-center">
                                    <h1 className="mb-10 text-white">Some Features that Made us Unique</h1>
                                    <Card.Text>
                                        Who are in extremely love with eco friendly system.
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                        <Row>
                            {featuresDetails.map(feature => <FeatureDetails key={`features-${feature.id}`} {...feature} />)}
                        </Row>
                    </Container>
                </Card>
                {/*/ End features Area */}

                {/* Start Generic Area */}
                <Card className="about-generic-area section-gap">
                    <Container className="border-top-generic">
                        <h3 className="about-title mb-30">Elaboration about Generic Page</h3>
                        <Row>
                            <Col lg={12} className='mb-20'>
                                <div className="img-text">
                                    <img src="assets/img/a1.jpg" alt="" className="img-fluid float-left mr-20 mb-20" />
                                    <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks such as Party Gaming and PlayTech left the United States. Overnight, online casino players found themselves being chased by the Federal government. But, after a fortnight, the online casino industry came up with a solution and new online casinos started taking root. These began to operate under a different business umbrella, and by doing that, rendered the transfer of money to and from them legal. A major part of this was enlisting electronic banking systems that would accept this new clarification and start doing business with me. Listed in this article are the electronic banking systems that accept players from the United States that wish to play in online casinos.</Card.Text>
                                </div>
                            </Col>
                            <Col lg={12} className='mb-20'>
                                <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks such as Party Gaming and PlayTech left the United States. Overnight, online casino players found themselves being chased by the Federal government. But, after a fortnight, the online casino industry came up with a solution and new online casinos started taking root. These began to operate under a different business umbrella, and by doing that, rendered the transfer of money to and from them legal. A major part of this was enlisting electronic banking systems that would accept this new clarification and start doing business with me. Listed in this article are the electronic banking systems that accept players from the United States that wish to play in online casinos.</Card.Text>
                            </Col>
                            <Col lg={12} className='mb-20'>
                                <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks such as Party Gaming and PlayTech left the United States. Overnight, online casino players found themselves being chased by the Federal government. But, after a fortnight, the online casino industry came up with a solution and new online casinos started taking root. These began to operate under a different business umbrella, and by doing that, rendered the transfer of money to and from them legal. A major part of this was enlisting electronic banking systems that would accept this new clarification and start doing business with me. Listed in this article are the electronic banking systems that accept players from the United States that wish to play in online casinos.</Card.Text>
                            </Col>
                            <Col lg={12} className='mb-20'>
                                <div className="img-text">
                                    <img src="assets/img/a2.jpg" alt="" className="img-fluid float-left mr-20 mb-20" />
                                    <Card.Text>Recently, the US Federal government banned online casinos from operating in America by making it illegal to transfer money to them through any US bank or payment system. As a result of this law, most of the popular online casino networks such as Party Gaming and PlayTech left the United States. Overnight, online casino players found themselves being chased by the Federal government. But, after a fortnight, the online casino industry came up with a solution and new online casinos started taking root. These began to operate under a different business umbrella, and by doing that, rendered the transfer of money to and from them legal. A major part of this was enlisting electronic banking systems that would accept this new clarification and start doing business with me. Listed in this article are the electronic banking systems that accept players from the United States that wish to play in online casinos.</Card.Text>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Card>
                {/* End Generic Start */}
            </div>
            {/*/ End About Generic */}
        </>
    );
};

export default Generic;