import React from 'react';
import { Accordion, Col, Container, Row, useAccordionButton } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { faqDetail } from "../../data/faq";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
        <h5 className="mb-0">
        <button
            type="button"
            className='btn btn-link'
            onClick={decoratedOnClick}
        >
            {children}
        </button>
      </h5>        
    );
}

const FaqDetail = (props) => {
    const { id, question, description, delay } = props;
    return (
        <Card className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card.Header id="headingOne">
                <CustomToggle eventKey={id}>{question}</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={id}>
                <Card.Body>{description}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

const Faq = () => {
    return (
        <>
            {/* Faq */}
            <Card id="faq" className="faq-area section-gap">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='menu-content pb-60'>
                            <Card.Body className="title text-center">
                                <h1 className="mb-10">Frequently Asked Questions</h1>
                                <Card.Text>
                                    Who are in extremely love with eco friendly system.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className='justify-content-start'>
                        <Col lg={6} className="faq-left">
                            <Accordion defaultActiveKey={1} id="accordion">
                                {faqDetail.map(faq => <FaqDetail key={`team-${faq.id}`} {...faq} />)}
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End faq */}

        </>
    );
};

export default Faq;