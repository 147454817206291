const servicesDetails = [
    { id: 1, title: "Expert Technicians",delay:"0.4s", icon:"user", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power. " },
    { id: 2, title: "Professional Service",delay:"0.5s", icon:"license", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power." },
    { id: 3, title: "Great Support",delay:"0.6s", icon:"phone", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power." },
    { id: 4, title: "Technical Skills",delay:"0.7s", icon:"rocket", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power." },
    { id: 5, title: "Highly Recomended",delay:"0.8s", icon:"diamond", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power." },
    { id: 6, title: "Positive Reviews",delay:"0.9s", icon:"bubble", description:"Usage of the Internet is becoming more common due to rapid advancement of technology and power." },
];

export {
    servicesDetails
};