import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { featuresDetails } from "../../data/feature";

const FeatureDetails = (props) => {
    const { delay, image } = props;
    return (
        <Col lg={3} md={6} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card.Body className="single-unique-product">
                <Image className="img-fluid" src={"assets/img/" + image} />
                <div className="desc">
                    <h4>
                        Apple Watch White
                    </h4>
                    <h6>£399.00</h6>
                    <Card.Link className="text-uppercase primary-btn" href="#">Pre Order</Card.Link>
                </div>
            </Card.Body>
        </Col>
    );
};

const Feature = () => {

    return (
        <>
            {/* Features Area */}
            <Card id="unique" className="unique-feature-area section-gap">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='menu-content pb-60'>
                            <Card.Body className="title text-center">
                                <h1 className="mb-10 text-white">Some Features that Made us Unique</h1>
                                <Card.Text>
                                    Who are in extremely love with eco friendly system.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row>
                        {featuresDetails.map(feature => <FeatureDetails key={`features-${feature.id}`} {...feature} />)}
                    </Row>
                </Container>
            </Card>
            {/*/ End features Area */}
        </>
    );
};

export default Feature;