import $ from 'jquery';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Banner = () => {

    useEffect(()=>{        
        $(".fullscreen").css("height", (window.innerHeight));
    });

    return (
        <>
            {/* Banner */}
            <Card id="home" className="banner-area">
                <Container>
                    <Row className='fullscreen d-flex align-items-center justify-content-center'>
                        <Col lg={10} className='banner-content wow flipInX' data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <h5 className="text-white text-uppercase">Now you can feel the Heat</h5>
                            <h1>
                                Smart New Future
                            </h1>
                            <Card.Link href="#" className="primary-btn text-uppercase">Buy Now</Card.Link>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Banner */}

        </>
    );
};

export default Banner;