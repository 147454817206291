import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { coursesDetailsLeft, coursesDetailsRight } from "../../data/coursedetail";

const CoursesDetailsLeft = (props) => {
    const { delay, icon } = props;
    return (
        <Card.Body className="single-course wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <span className={"lnr lnr-" + icon}></span>
            <Card.Link href='#'><h4>High Performance</h4></Card.Link>            
            <Card.Text>Lorem ipsum dolor sit amet, consectetur adipisic ing elit, seddo eiusmod tempor incid.idunt ut labore et dolore magna aliqua.</Card.Text>
        </Card.Body>
    );
};

const CoursesDetailsRight = (props) => {
    const { delay, icon } = props;
    return (
        <Card.Body className="single-course wow fadeInRight" data-wow-duration="0.8s" data-wow-delay={delay}>
            <span className={"lnr lnr-" + icon}></span>
            <Card.Link href='#'><h4>High Performance</h4></Card.Link>            
            <Card.Text>Lorem ipsum dolor sit amet, consectetur adipisic ing elit, seddo eiusmod tempor incid.idunt ut labore et dolore magna aliqua.</Card.Text>
        </Card.Body>
    );
};


const TopCourse = () => {
    return (
        <>
            {/* TopCourse */}
            <Card id="top-course" className="top-course-area section-gap">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='menu-content pb-60'>
                            <Card.Body className="title text-center">
                                <h1 className="mb-10">Top Courses That are open for Students</h1>
                                <Card.Text>
                                    Who are in extremely love with eco friendly system.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={3} className="course-left">
                            {coursesDetailsLeft.map(cleft => <CoursesDetailsLeft key={`cleft-${cleft.id}`} {...cleft} />)}
                        </Col>
                        <Col lg={6} className="course-middle wow flipInX" data-wow-duration="0.8s" data-wow-delay="0.7s">
                            <Image className="img-fluid mx-auto d-block" src="assets/img/c1.png"/>
                        </Col>
                        <Col lg={3} className="course-right">
                            {coursesDetailsRight.map(cright => <CoursesDetailsRight key={`cright-${cright.id}`} {...cright} />)}
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End TopCourse */}
        </>
    );
};

export default TopCourse;