import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { reviewDetails } from "../../data/review";

const ReviewDetails = (props) => {
    const { title, description, totalstar, delay } = props;
    return (
        <Col lg={4} md={6} className="wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay={delay}>
            <Card className="single-review">
                <h4>{title}</h4>
                <Card.Text>{description}</Card.Text>
                <div className="star">
                    {[...Array(5)].map((el, i) =>
                        i < totalstar ? <span key={i} className="fa fa-star checked" style={{paddingRight:'4px'}}></span>
                        : <span key={i} className="fa fa-star" style={{paddingRight:'4px'}}></span>
                    )}
                </div>
            </Card>
        </Col>
    );
};

const Review = () => {

    return (
        <>
            {/* Review Area */}
            <Card id="review" className="review-area section-gap">
                <Container>
                    <Row className='d-flex justify-content-center'>
                        <Col lg={10} className='menu-content pb-60'>
                            <Card.Body className="title text-center">
                                <h1 className="mb-10">Some Features that Made us Unique</h1>
                                <Card.Text>
                                    Who are in extremely love with eco friendly system.
                                </Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                    <Row className='justify-content-start'>
                        {reviewDetails.map(review => <ReviewDetails key={`review-${review.id}`} {...review} />)}
                    </Row>
                </Container>
            </Card>
            {/*/ End review Area */}
        </>
    );
};

export default Review;