import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const Banner = () => {

    return (
        <>
            {/* Banner */}
            <Card className="generic-banner relative">
                <Container>
                    <Row className='height align-items-center justify-content-center'>
                        <Col lg={10}>
                            <Card.Body className="generic-banner-content">
                                <h2 className="text-white">The Elements Page</h2>
                                <Card.Text className="text-white">It won’t be a bigger problem to find one video game lover in your <br /> neighbor. Since the introduction of Virtual Game.</Card.Text>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End Banner */}


        </>
    );
};

export default Banner;