import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from '../commonPages/Footer';
import Header from '../commonPages/Header';

const Layout = () => {

    useEffect(() => {
        window.addEventListener("click", () => {
            var header = document.getElementById("nav");
            var PageLi = header.getElementsByClassName("PageLi");
            PageLi[0].classList.remove('sfHover');
        });
    })

    return (
        <>
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        </>
    );
};

export default Layout;