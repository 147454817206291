import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const HomeAbout = () => {
    return (
        <>
            {/* About */}
            <Card id="home-about" className="home-about-area">
                <Container fluid>
                    <Row className='d-flex justify-content-center align-items-center'>
                        <Col lg={6} className='no-padding home-about-left wow fadeInLeft' data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <Image className="img-fluid" src={"assets/img/about-img.jpg"} />
                        </Col>
                        <Col lg={6} className="no-padding home-about-right wow fadeInRight" data-wow-duration="0.8s" data-wow-delay="0.9s">
                            <h1>Globally Connected <br />
                                by Large Network</h1>
                            <Card.Text>
                                <span>We are here to listen from you deliver exellence</span>
                            </Card.Text>
                            <Card.Text>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                            </Card.Text>
                            <Card.Link className="text-uppercase primary-btn" href="#">get details</Card.Link>
                        </Col>
                    </Row>
                </Container>
            </Card>
            {/*/ End About */}

        </>
    );
};

export default HomeAbout;