import React from 'react';
import { Image } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';


const TableData = [
    { id: 1, img: 'f1.jpg', country: 'Canada', visit: 645032, class: 'color-1', width: 80 },
    { id: 2, img: 'f2.jpg', country: 'Canada', visit: 645032, class: 'color-2', width: 30 },
    { id: 3, img: 'f3.jpg', country: 'Canada', visit: 645032, class: 'color-3', width: 55 },
    { id: 4, img: 'f4.jpg', country: 'Canada', visit: 645032, class: 'color-4', width: 60 },
    { id: 5, img: 'f5.jpg', country: 'Canada', visit: 645032, class: 'color-5', width: 40 },
    { id: 6, img: 'f6.jpg', country: 'Canada', visit: 645032, class: 'color-6', width: 70 },
    { id: 7, img: 'f7.jpg', country: 'Canada', visit: 645032, class: 'color-7', width: 30 },
    { id: 8, img: 'f8.jpg', country: 'Canada', visit: 645032, class: 'color-8', width: 60 },
];

const Table = () => {

    return (
        <>
            <Card className="section-top-border">
                <h3 className="mb-30">Table</h3>
                <div className="progress-table-wrap">
                    <table className="progress-table" style={{ width: '100%' }}>
                        <thead>
                            <tr className="table-head" style={{ paddingTop: '15px' }}>
                                <th className="serial">#</th>
                                <th className="country">Countries</th>
                                <th className="visit">Visits</th>
                                <th className="percentage">Percentages</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TableData.map((data,index) => (
                                <tr className="table-row" key={index}>
                                    <td className="serial">0{data.id}</td>
                                    <td className="country"> <Image src={"assets/img/elements/" + data.img} alt="flag" />{data.country}</td>
                                    <td className="visit">{data.visit}</td>
                                    <td className="percentage">
                                        <div className="progress">
                                            <div className={"progress-bar " + data.class} role="progressbar" style={{ width: data.width + '%' }} aria-valuenow={data.width} aria-valuemin={0} aria-valuemax={100} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Card>
        </>
    );
};

export default Table;