const coursesDetailsLeft = [
    { id: 1, delay: "0.4s", icon: "rocket" },
    { id: 2, delay: "0.6s", icon: "cog" }
];
const coursesDetailsRight = [
    { id: 1, delay: "0.4s", icon: "apartment" },
    { id: 2, delay: "0.6s", icon: "phone" }
];

export {
    coursesDetailsLeft,
    coursesDetailsRight
};