import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

const RightAligned = () => {

    return (
        <>
            <Card className="section-top-border text-right">
                <h3 className="mb-30">Right Aligned</h3>
                <Row>
                    <Col md={9}>
                        <Card.Text className="text-right">Over time, even the most sophisticated, memory packed computer can begin to run slow if we don’t do something to prevent it. The reason why has less to do with how computers are made and how they age and more to do with the way we use them. You see, all of the daily tasks that we do on our PC from running programs to downloading and deleting software can make our computer sluggish. To keep this from happening, you need to understand the reasons why your PC is getting slower and do something to keep your PC running at its best. You can do this through regular maintenance and PC performance optimization programs</Card.Text>
                        <Card.Text className="text-right">Before we discuss all of the things that could be affecting your PC’s performance, let’s talk a little about what symptoms</Card.Text>
                    </Col>
                    <Col md={3}>
                        <Image src="assets/img/elements/d.jpg" alt="" className="img-fluid" />
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default RightAligned;