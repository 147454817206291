import React from 'react';
import Banner from './Banner';
import Faq from './Faq';
import Feature from './Feature';
import HomeAbout from './HomeAbout';
import Review from './Review';
import Service from './Service';
import TopCourse from './TopCourse';
import VideoAbout from './VideoAbout';

const Home = () => {

    return (
        <>
        <Banner/>
        <VideoAbout/>
        <TopCourse/>
        <HomeAbout/>
        <Service/>
        <Feature/>
        <Review/>
        <Faq/>
        </>
    )
}

export default Home

