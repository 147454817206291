import React from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";


const Footer = () => {
    return (
        <>
            {/* start footer Area */}
            <footer className="footer-area section-gap">
                <Container>
                    <Row>
                        <Col lg={5} md={6} sm={6}>
                            <Card className="single-footer-widget">
                                <h6>About Us</h6>
                                <Card.Text>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.
                                </Card.Text>
                                <Card.Text className="footer-text">
                                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                    Copyright ©{(new Date().getFullYear())} All rights reserved | This template is made with <i className="fa fa-heart-o" aria-hidden="true" /> by <Card.Link href="/#home" target="_blank">Codeyes</Card.Link>
                                    {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                                </Card.Text>
                            </Card>
                        </Col>
                        <Col lg={5} md={6} sm={6}>
                            <Card className="single-footer-widget">
                                <h6>Newsletter</h6>
                                <Card.Text>Stay update with our latest</Card.Text>
                                <Card.Body id="mc_embed_signup" className="card-body">
                                    <Form target="_blank" noValidate action="#" method="get" className="form-inline">
                                        <Form.Control as='input' className="form-control" name="EMAIL" placeholder="Enter Email" type="email" />
                                        <Button className="click-btn btn btn-default"><i className="fa fa-long-arrow-right" aria-hidden="true" /></Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={2} md={6} sm={6}>
                            <Card className="single-footer-widget">
                                <h6>Follow Us</h6>
                                <Card.Text>
                                    Let us be social
                                </Card.Text>
                                <Card.Footer className="footer-social d-flex align-items-center">
                                    <Card.Link href="#"><i className="fa fa-facebook" /></Card.Link>
                                    <Card.Link href="#"><i className="fa fa-twitter" /></Card.Link>
                                    <Card.Link href="#"><i className="fa fa-dribbble" /></Card.Link>
                                    <Card.Link href="#"><i className="fa fa-behance" /></Card.Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </footer >
            {/* End footer Area */}
        </>
    );
};

export default Footer;