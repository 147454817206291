import $ from 'jquery';
import React, { useEffect } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';


const FormElements = () => {
    useEffect(() => {
        $(document).ready(function () {
            window.$('#default-select').niceSelect();
            window.$('#default-select2').niceSelect();
            window.$('#default-select3').niceSelect();
        });
    });
    return (
        <>
            <Card className="section-top-border">
                <Row>
                    <Col lg={8} md={8}>
                        <h3 className="mb-30">Form Element</h3>
                        <Form>
                            <Form.Group className="mt-10">
                                <Form.Control type="text" name="first_name" placeholder="First Name" className="single-input" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "First Name"} />
                            </Form.Group>
                            <Form.Group className="mt-10">
                                <Form.Control type="text" name="last_name" placeholder="Last Name" className="single-input" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Last Name"} />
                            </Form.Group>
                            <Form.Group className="mt-10">
                                <Form.Control type="email" name="email" placeholder="Email address" className="single-input" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Email address"} />
                            </Form.Group>
                            <InputGroup className="mt-10">
                                <InputGroup.Text id="basic-addon1" className="icon"><i className="fa fa-thumb-tack" aria-hidden="true"></i></InputGroup.Text>
                                <Form.Control type="text" name="address" placeholder="Address" className="single-input" aria-describedby="basic-addon1" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Address"} />
                            </InputGroup>
                            <InputGroup className="mt-10">
                                <InputGroup.Text id="basic-addon2" className="icon"><i className="fa fa-plane" aria-hidden="true"></i></InputGroup.Text>
                                <Form.Select id="default-select" className='input-select' aria-describedby="basic-addon2">
                                    <option value="1">City</option>
                                    <option value="2">Dhaka</option>
                                    <option value="3">Dilli</option>
                                    <option value="4">Newyork</option>
                                    <option value="5">Islamabad</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mt-10">
                                <InputGroup.Text id="basic-addon4" className="icon"><i className="fa fa-globe" aria-hidden="true"></i></InputGroup.Text>
                                <Form.Select id="default-select2" className='input-select' aria-describedby="basic-addon4">
                                    <option value="1">Country</option>
                                    <option value="2">Bangladesh</option>
                                    <option value="3">India</option>
                                    <option value="4">England</option>
                                    <option value="5">Srilanka</option>
                                </Form.Select>
                            </InputGroup>
                            <Form.Group className="mt-10">
                                <Form.Control as="textarea" rows={3} name="Message" placeholder="Message" className="single-textarea" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Message"} />
                            </Form.Group>
                            <Form.Group className="mt-10">
                                <Form.Control type="text" name="Primarycolor" placeholder="Primary color" className="single-input-primary input-color" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Primary color"} />
                            </Form.Group>
                            <Form.Group className="mt-10">
                                <Form.Control type="text" name="Accentcolor" placeholder="Accent color" className="single-input-accent input-color" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Accent color"} />
                            </Form.Group>
                            <Form.Group className="mt-10">
                                <Form.Control type="text" name="Secondarycolor" placeholder="Secondary color" className="single-input-secondary input-color" onFocus={(e) => e.target.placeholder = ""} onBlur={(e) => e.target.placeholder = "Secondary color"} />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col lg={3} md={4} className=' mt-sm-30'>
                        <Card className="single-element-widget">
                            <h3 className="mb-30">Switches</h3>
                            <div className="switch-wrap d-flex justify-content-between">
                                <Card.Text>01. Sample Switch</Card.Text>
                                <div className="primary-switch">
                                    <input type="checkbox" id="default-switch" />
                                    <label htmlFor="default-switch" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <Card.Text>02. Primary Color Switch</Card.Text>
                                <div className="primary-switch">
                                    <input type="checkbox" id="primary-switch" defaultChecked />
                                    <label htmlFor="primary-switch" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <Card.Text>03. Confirm Color Switch</Card.Text>
                                <div className="confirm-switch">
                                    <input type="checkbox" id="confirm-switch" defaultChecked />
                                    <label htmlFor="confirm-switch" />
                                </div>
                            </div>
                        </Card>
                        <Card className="single-element-widget mt-30 default-select3">
                            <h3 className="mb-30">Selectboxes</h3>
                            <Form.Select id="default-select3" className='single-input input-select' aria-describedby="basic-addon2">
                                <option value={1}>English</option>
                                <option value={1}>Spanish</option>
                                <option value={1}>Arabic</option>
                                <option value={1}>Portuguise</option>
                                <option value={1}>Bengali</option>
                            </Form.Select>
                        </Card>
                        <Card className="single-element-widget mt-30">
                            <h3 className="mb-30">Checkboxes</h3>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>01. Sample Checkbox</p>
                                <div className="primary-checkbox">
                                    <input type="checkbox" id="default-checkbox" />
                                    <label htmlFor="default-checkbox" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>02. Primary Color Checkbox</p>
                                <div className="primary-checkbox">
                                    <input type="checkbox" id="primary-checkbox" defaultChecked />
                                    <label htmlFor="primary-checkbox" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>03. Confirm Color Checkbox</p>
                                <div className="confirm-checkbox">
                                    <input type="checkbox" id="confirm-checkbox" />
                                    <label htmlFor="confirm-checkbox" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>04. Disabled Checkbox</p>
                                <div className="disabled-checkbox">
                                    <input type="checkbox" id="disabled-checkbox" disabled />
                                    <label htmlFor="disabled-checkbox" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>05. Disabled Checkbox active</p>
                                <div className="disabled-checkbox">
                                    <input type="checkbox" id="disabled-checkbox-active" defaultChecked disabled />
                                    <label htmlFor="disabled-checkbox-active" />
                                </div>
                            </div>
                        </Card>
                        <Card className="single-element-widget mt-30">
                            <h3 className="mb-30">Radios</h3>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>01. Sample radio</p>
                                <div className="primary-radio">
                                    <input type="checkbox" id="default-radio" />
                                    <label htmlFor="default-radio" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>02. Primary Color radio</p>
                                <div className="primary-radio">
                                    <input type="checkbox" id="primary-radio" defaultChecked />
                                    <label htmlFor="primary-radio" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>03. Confirm Color radio</p>
                                <div className="confirm-radio">
                                    <input type="checkbox" id="confirm-radio" defaultChecked />
                                    <label htmlFor="confirm-radio" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>04. Disabled radio</p>
                                <div className="disabled-radio">
                                    <input type="checkbox" id="disabled-radio" disabled />
                                    <label htmlFor="disabled-radio" />
                                </div>
                            </div>
                            <div className="switch-wrap d-flex justify-content-between">
                                <p>05. Disabled radio active</p>
                                <div className="disabled-radio">
                                    <input type="checkbox" id="disabled-radio-active" defaultChecked disabled />
                                    <label htmlFor="disabled-radio-active" />
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row >
            </Card >
        </>
    );
};

export default FormElements;